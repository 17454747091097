<template>
  <div class="container-fluid px-4 py-5">
    <div class="row">
      <div :class="{ 'col-md-9': showMeta, 'col-md-12': !showMeta }">
        <SettingsMain />
      </div>
      <!-- <div v-if="showMeta" class="col-md-3 metaview">
        <MetaView />
      </div> -->
    </div>
  </div>
</template>

<script>
import SettingsMain from '@/common/widgets/SettingsMainWidget/SettingsMainWidget.vue';

export default {
  name: 'Settings',
  metaInfo: {
    title: 'Settings',
  },
  components: {
    // MetaView,
    SettingsMain,
  },

  computed: {
    showMeta() {
      if (this.$store.state.wikiInformation) {
        const wikiInfoLength = this.$store.state.wikiInformation.length;
        if (wikiInfoLength > 0) {
          return true;
        }
      }
      if (
        this.$store.state.selectedNode
        && this.$store.state.selectedNode.nodeLabel
      ) {
        return true;
      }

      if (
        this.$store.state.wolframInformation
        && this.$store.state.wolframInformation.answer
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.metaview {
  z-index: 10;
}
</style>
